<template>
  <div class="mx-auto max-w-[340px] space-y-8 text-center sm:max-w-xl">
    <div class="rounded-lg border border-cream bg-white p-4 pb-6">
      <div class="mb-5 flex items-center gap-x-2 text-left">
        <Icon name="logo-shield" class="h-11 w-11 shrink-0 text-yellow" />
        <h2 class="text-xl font-semibold leading-5">
          The faster, easier way to help your pets be healthier
        </h2>
      </div>
      <ul class="grid gap-x-3 gap-y-4 pl-5 sm:grid-cols-2 sm:justify-center">
        <li
          v-for="benefit in benefits"
          :key="benefit"
          class="flex items-center gap-3 overflow-hidden text-sm">
          <Icon name="check-fill" class="h-4 w-4" />
          <h4>{{ benefit }}</h4>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
const benefits = [
  "Fast access to licensed vets",
  "Unlimited video calls & follow-ups",
  "Virtual care for up to 5 pets",
  "Guaranteed low prices on medication",
  "Customized Rx treatment plans",
  "Free shipping on every order",
];
</script>
