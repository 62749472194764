import Login from "@/vue/apps/Login.vue";
import pinia from "@/vue/store/index";
import { loadSentry } from "@/js/lib/sentry/sentry-vue";

const AccountApp = () => {
  const app = createApp(Login);
  app.use(pinia);

  loadSentry({ app, appName: "Login" });

  return app;
};
AccountApp().mount('[vue="login"]');
