<template>
  <div class="flex w-full items-center justify-center pb-20 pt-8">
    <Transition appear mode="out-in">
      <div
        v-if="!showReset && !showRecover"
        class="w-full max-w-2xl px-5 sm:space-y-8">
        <img
          :src="topImage"
          alt="Family image"
          class="relative mx-auto w-[calc(100%+40px)] max-w-none -translate-x-5 -translate-y-8 sm:w-full sm:translate-x-0 sm:translate-y-0 sm:rounded-md"
          loading="eager" />

        <div class="max-w-screen-sm md:px-12">
          <div class="mb-8 text-center">
            <h1 class="text-center font-serif text-[28px] leading-tight">
              Welcome back!
            </h1>
            <p class="my-4 px-6 text-lg leading-snug">
              Log in to connect with a vet, refill <br />
              prescriptions, shop the pharmacy & more.
            </p>
          </div>

          <form
            class="m-auto flex flex-col gap-4 text-left"
            @submit.prevent="onLogin">
            <InputText
              v-model="email"
              label="Email"
              id="email"
              type="email"
              placeholder="Email" />
            <InputText
              v-model="password"
              label="Password"
              id="password"
              type="password"
              placeholder="Password" />

            <div class="my-1 text-right">
              <button
                type="button"
                class="cursor-pointer text-sm hover:underline"
                @click.prevent="toggleReset">
                Forgot password?
              </button>
            </div>

            <Button
              class="button button-lg mx-auto my-4 block w-full max-w-xs transition-colors"
              :disabled="loading"
              type="submit">
              <template v-if="loading">Loading...</template>
              <template v-else>Log in</template>
            </Button>
          </form>

          <Transition name="nested">
            <ul
              v-if="errors.length"
              class="my-4 rounded-md bg-red bg-opacity-10 p-4 text-center font-condensed text-sm uppercase leading-tight tracking-wider text-red">
              <li v-for="error in errors" :key="error.message">
                <div v-html="error.message"></div>
              </li>
            </ul>
            <ul
              v-else-if="activationInvalid"
              class="my-4 rounded-md bg-red bg-opacity-10 p-4 text-center font-condensed text-sm uppercase leading-tight tracking-wider text-red">
              <li>Activation token has been used or is expired.</li>
            </ul>
          </Transition>

          <!-- After account created via Storefront API, submit this form with email and password -->
          <LiquidLoginSnippet
            :email="email"
            :password="password"
            :return-to="returnToWithHash ? returnToWithHash : '/account'"
            ref="liquidForm" />

          <div class="my-1 mt-4 space-y-8 py-2 text-center">
            <div
              class="flex items-center justify-between gap-4 whitespace-nowrap font-condensed text-sm uppercase leading-none tracking-wider">
              <div class="mt-px w-full border-t border-cream"></div>
              Or log in with
              <div class="mt-px w-full border-t border-cream"></div>
            </div>
            <ul class="grid auto-cols-fr grid-flow-col gap-3 md:gap-4">
              <li v-for="social in providers" :key="social.auth_provider">
                <a
                  :href="`${ssoUrl}&auth-provider=${social.auth_provider}`"
                  class="button relative inline-flex h-[56px] w-full max-w-80 items-center justify-center space-x-2 border border-black bg-white px-10 py-4 text-black hover:border-transparent hover:bg-white">
                  <Icon :name="social.icon" class="h-5 w-5 shrink-0" />
                  <span>{{ social.label }}</span>
                </a>
              </li>
            </ul>

            <div class="flex flex-col justify-center gap-y-6">
              <MembershipBenefitsNew class="order-last sm:order-first" />

              <p class="px-4 text-sm leading-snug md:px-16">
                Not a member? <br class="sm:hidden" />
                Get care & prescriptions, quickly from home.
                <a
                  href="/account/register"
                  class="block font-bold text-orange underline">
                  Sign up now
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Transition>

    <Transition appear>
      <div
        v-if="showReset || showRecover"
        class="max-w-screen-sm px-6 md:px-16">
        <div class="m-auto w-full">
          <h2
            class="mb-4 text-center font-serif text-4xl leading-tight md:text-5xl">
            Forgot your password?
          </h2>
          <div class="mb-4 px-6 text-center text-lg leading-snug">
            <p>
              If we’ve found your account, you will receive an email with
              instructions to reset your password.
            </p>
          </div>

          <Transition name="fade">
            <ul
              v-if="errors.length"
              class="my-4 rounded-md bg-red bg-opacity-10 p-4 font-condensed uppercase tracking-wider text-red">
              <li v-for="error in errors" :key="error.message">
                <div v-html="error.message"></div>
              </li>
            </ul>
          </Transition>

          <Transition name="fade">
            <div>
              <form
                @submit.prevent="onRecover"
                class="mx-auto"
                v-if="!recoverSent">
                <div class="my-1 p-2">
                  <label for="email_customer[email]">Email</label>
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    v-model="email"
                    id="email_customer[email]"
                    class="mb-4"
                    autocomplete="off"
                    autocapitalize="off"
                    spellcheck="false" />
                </div>

                <div class="my-1 p-2 text-center">
                  <button
                    type="submit"
                    class="button button-lg relative"
                    role="button"
                    :disabled="loading">
                    <template v-if="loading">Loading...</template>
                    <template v-else>Reset password</template>
                  </button>
                </div>
              </form>

              <button
                class="mx-auto mt-4 block text-sm underline"
                @click.prevent="toggleReset">
                Cancel
              </button>
            </div>
          </Transition>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import topImage from "@/static/login-top-image.png";

const { providers, loginUrl } = useSso();
const store = useCustomer();
const { errors, loading } = storeToRefs(store);
const email = ref("");
const showReset = ref(false);
const recoverSent = ref(false);
const liquidForm = ref(null) as any;
const showRecover = computed(() => window.location.hash === "#recover");
const password = ref("");
const params = useUrlSearchParams("history");
const { segmentTrack } = useAnalytics();

const returnToWithHash = computed(() => {
  // If coming from gift card PDP, return to checkout
  if (document.referrer.includes("/products/dutch-gift-card")) {
    return "/checkout";
  }

  // Preserve any return urls
  const returnUrl =
    params.checkout_url || params.return_url || params.return_to;

  if (returnUrl) {
    return `${returnUrl}${window.location.hash}`;
  }

  return null;
});

const returnTo = computed(() =>
  returnToWithHash.value
    ? returnToWithHash.value
    : `${window.location.protocol}//${window.location.host}/account`
);

const ssoUrl = computed(() =>
  loginUrl({ searchParams: { return_to: returnTo.value } })
);

const activationInvalid = computed(
  () => location.pathname === "/account/invalid_token"
);

function toggleReset() {
  showReset.value = !showReset.value;
  store.$patch((state) => (state.customerUserErrors = []));
  window.location.hash = window.location.hash === "#recover" ? "" : "#recover";
}

async function onLogin() {
  await store.login(email.value, password.value);

  if (errors.value.length) {
    segmentTrack("Login Failed", {
      email: email.value,
    });
    return;
  }

  segmentTrack("Login Success", {
    email: email.value,
    type: "PW",
  });

  segmentTrack("Signed In", {
    email: email.value,
  });
  liquidForm?.value?.loginFormRef.submit();
}

async function onRecover() {
  await store.recover(email.value);

  if (errors.value.length) return;
  recoverSent.value = true;
}
</script>

<style>
#gorgias-chat-container {
  display: none;
}
</style>
